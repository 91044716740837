<template>
  <section class="mb-10">
    <header
      class="
        grid grid-cols-4
        lg:grid-cols-12
        gap-4
        items-end
        justify-between
        my-6
        lg:my-10
        px-6
        lg:px-10
      "
    >
      <h2
        class="
          text-xl
          font-semibold
          tracking-wider
          max-w-3xl
          lg:text-2xl
          col-span-2
          lg:col-span-12
        "
      >
        {{ title }}
      </h2>

      <div
        class="
          col-span-2
          flex
          justify-end
          lg:col-start-9 lg:col-span-4 lg:row-start-2
        "
      >
        <Link
          v-if="$u(link) !== null"
          :label="linkLabel"
          :link="link"
          :underline="true"
        />
      </div>
      <div class="col-span-4 lg:col-span-8 lg:row-start-2 lg:col-start-1">
        <p
          class="
            relative
            tracking-wider
            prose
            text-sm
            lg:text-base
            text-black
            max-w-3xl
          "
        >
          {{ description }}
        </p>
      </div>
    </header>
    <div :id="dynamicId" class="w-full"></div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Flowbox',
  props: {
    flowboxKey: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
    linkLabel: {
      type: String,
      required: false,
      default: '',
    },
    link: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
  data() {
    return {
      dynamicId: null,
    }
  },
  commputed: {
    ...mapGetters({
      currentLanguageCode: 'frontend/currentLanguageCode',
      currentCountryCode: 'frontend/currentCountryCode',
    }),
  },
  beforeMount() {
    /* Load script from flowbox */
    ;(function (d, id) {
      if (!window.flowbox) {
        const f = function () {
          f.q.push(arguments)
        }
        f.q = []
        window.flowbox = f
      }
      if (d.getElementById(id)) {
        return
      }
      const s = d.createElement('script')
      const fjs = d.scripts[d.scripts.length - 1]
      s.id = id
      s.async = true
      s.src = 'https://connect.getflowbox.com/flowbox.js'
      fjs.parentNode.insertBefore(s, fjs)
    })(document, 'flowbox-js-embed')
  },
  mounted() {
    this.dynamicId = `flowbox-${this._uid}`

    if (!window.flowbox) {
      console.error('Flowbox was not found')
      return
    }

    window.flowbox('init', {
      container: `#${this.dynamicId}`,
      key: this.flowboxKey,
      locale: this.currentLanguageCode,
    })
  },
}
</script>
